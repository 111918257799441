import 'scss/pages/about-us.scss';
import React from 'react';
import { Trans } from '@lingui/macro';
import Layout from 'components/Layout';
import Seo from 'components/SEO';
import Image from 'components/atoms/Image/Image';
import {Link} from 'gatsby';
import {List, ListItem} from 'components/atoms/List/List';
import {careerContactFormProps, Perks} from "../../page-components/careers/common";

const SeniorBackendDeveloper = () => {
  return (
      <Layout contactFormProps={careerContactFormProps} >
        <Seo title="Senior Backend Developer at YanchWare"/>
        <section className="hero-wrapper">
          <div className="hero">
            <div className="hero__content">
              <h1 className="hero__title">
                <Trans>Senior Backend Developer @ YanchWare</Trans>
              </h1>
              <p className="hero__text">
                <Trans>
                  Join our Avanguardia team as a Senior Backend Developer and spearhead the advancement of our
                  cutting-edge cloud products, Fractal and Ocelot. Your role will focus on innovation and leadership
                  in cloud computing, ensuring that our solutions continue to lead the market globally.
                </Trans>
              </p>
              <Link to="/contact-us" className="bg-green-900 w-[190px] mt-m py-xs px-m rounded-[10px] flex justify-between">
                <span className="text-transparent bg-clip-text bg-hero-text">
                  <Trans>Apply now</Trans>
                </span>
                <Image name="arrow-icon" className="fill-icon mr-s" />
              </Link>
            </div>
            <Image name="careers/senior-backend-developer" className="hero__image"/>
          </div>
        </section>

        <section className="container tac">
          <h2 className="color-primary">
            <Trans>About the role</Trans>
          </h2>

          <div className="content-blocks content-blocks--reverse">
            <div className="content-block">
              <Image
                  name="about-us-page/customer-success"
                  className="content-block__image"
              />
              <div className="content-block__info">
                <h2>
                  <Trans>Responsibilities:</Trans>
                </h2>
                <p>
                  <Trans>
                    In this pivotal role, you will be instrumental in shaping the backbone of our products.
                    Your responsibilities will include:
                  </Trans>
                </p>
                <p>
                  <List>
                    <ListItem>
                      <Trans>
                        Extending product functionalities with a focus on robust microservices and automated
                        infrastructure provisioning.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Creating automation subroutines to streamline operations, enhancing efficiency and reliability.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Upholding the highest standards of resilience, reliability, responsiveness, availability, and
                        security in our solutions.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Providing expert technical support and defining automation templates to facilitate customer
                        adoption.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Collaborating on strategic product and cloud strategy development, ensuring continuous
                        evolution and leadership in the market.
                      </Trans>
                    </ListItem>

                  </List>
                </p>
              </div>
            </div>
            <div className="content-block">
              <Image
                  name="about-us-page/about-us-hero"
                  className="content-block__image"
              />
              <div className="content-block__info">

                <h2>
                  <Trans>Success Criteria:</Trans>
                </h2>
                <p>
                  <Trans>
                    Your success will be defined by your ability to deliver on key objectives that enhance our product
                    offerings and customer satisfaction:
                  </Trans>
                </p>
                <p>
                  <List>
                    <ListItem>
                      <Trans>
                        Strategically defining and implementing cloud strategies for each product, marked by clear
                        milestones and efficient team collaboration.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Establishing and promoting best practices in cloud development and architecture throughout
                        the organization.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Identifying and automating repetitive operational tasks, maintaining rigorous documentation
                        and consistent delivery.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Ensuring all cloud solutions are resilient, reliable, highly available, responsive, and secure.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Developing accessible and impactful educational programs for a broad audience, including
                        non-technical stakeholders.
                      </Trans>
                    </ListItem>
                  </List>
                </p>
              </div>
            </div>
            <div className="content-block">
              <Image
                  name="careers/whoareu"
                  className="content-block__image"
              />
              <div className="content-block__info">
                <h2>
                  <Trans>Who are you:</Trans>
                </h2>
                <p>
                  <Trans>
                    You are a visionary and a technologist at heart, driven by the desire to innovate and lead in the
                    field of cloud computing:
                  </Trans>
                </p>
                <p>
                  <List>
                    <ListItem>
                      <Trans>
                        Skilled in multiple programming languages with a particular focus on backend solutions written
                        in Java and/or C# running in cloud environments.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        A collaborative team player who is enthusiastic about sharing knowledge and enhancing
                        the capabilities of those around you.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Motivated by challenges and committed to simplifying cloud adoption to have a lasting impact
                        on the industry and community.
                      </Trans>
                    </ListItem>
                  </List>
                </p>
              </div>
            </div>
          </div>
          <Perks/>
        </section>
      </Layout>
  );
};

export default SeniorBackendDeveloper;
